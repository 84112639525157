.landing-container {
    background-color: var(--bg-main);
    color: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0 7% 0;
    min-height: 80vh;

}

.landing-win {
    width: 70%;
    height: 80%;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;

}

.landing-win-inner {
    width: 50%;
    padding: 5%;

}


.l-w-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    background-color: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}



.l-w-left h2{
    text-transform: none;
    letter-spacing: normal;
}

.l-w-right {
    background-color: transparent;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:  var(--white);
}

.l-w-right img {
    width:70%;
    margin: 0px;
}

.link {
    width: 80%;
}

.d-btn {
    height: 40px;
    width: 100%;
    margin: 7px;
    border: none;
    border-radius: 30px;
    background-color: var(--bg-secondary);
    color: white;
    text-align: center;
}

.d-btn:hover {
    opacity: .7;
}

.d-btn:active {
    opacity: 1;
}



@media screen and (max-width:800px) {
    .landing-win-inner {
        width: 100%;
    }
    .landing-win {
        width: 90%;
    }
    .l-w-left {
        border-radius: 0px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .l-w-right {
        border-radius: 0px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}
