:root {
  --bg-main:#5468b7;
  --bg-secondary:#8294db;
  --green: #87c761;
  --bg-comp:#d1a48e;
  --bg-dark: #525e8d;
  --black:rgb(46, 46, 46);
  --white:#f1f1f1;
  --logo-sec:#d9b9aa;
  --logo-prime:#a1826f;
}


.App {
  text-align: center;
  
}


* {
  box-sizing:border-box;
  -moz-box-sizing:border-box;
}

li {
  list-style: none;
}


input {
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  border: none;
  padding: 10px;
  margin: 3px;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  padding: 0;
}
 
h1, h2, h3, h4, h5, h6 {
  letter-spacing: 5px;
  font-weight: 900;
  font-family: 'Playfair display';
  text-transform: uppercase;
  margin: 0;
}

h1 {
  font-size: calc(2em + 3vw);
}

h2 {
  font-size: calc(2em + 2vw);
}

h3 {
  font-size: calc(2em + 1vw);
}

h4 {
  font-size: calc(2em + .5vw);
}

h5 {
  font-size: calc(1.2em);
}

h6 {
  font-size: calc(1em);
}

img {
  color: transparent;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}


.greeting {
  color: var(--bg-main);
}

.add {
  color: white;
  background-color: var(--bg-main);
}

.remove {
  color: white;
  background-color: #d46464;
}

.update {
  color: black;
  background-color: #efdb5d;
}