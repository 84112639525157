.resume-container {
    background-color: var(--bg-main);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.project-container {
    width: 90%;
    margin: 70px;
}

.resume-div {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.resume-div img {
    max-width: 500px;
}

.projects-div {
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.project-post-body {
    height: 250px;
    width: 350px;
    margin: 1%;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-size: 80%;
    background-blend-mode:color-burn;
    background-position:center;
}

.project-post-body a{
    text-decoration: none;
    color: #262626;
    font-size: 25px;
}

.project-post-body a:hover{
    color: #262626ac;
}

.post-section {
    background-color: rgba(255, 255, 255, 0.7);
    margin: 5px;
    border-radius: 10px;
    padding: 5px;
}

.font-small {
    text-align: left;
    font-size: 13px;
}

.btn-res {
    width: 40%;
    height: 80px;

    background-color: #dd4600;
    color: var(--white);

    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: none !important;
    font-size: 2em;
    box-shadow: 0 9px #cd1a1a;
}

.btn-res:hover {
    opacity: .9;
}


.btn-res:active {
    box-shadow: 0 5px #b41b1b;
    transform: translateY(5px);
}


@media screen and (max-width: 600px) {
    .btn-res {
        width: 80%;
    }
}