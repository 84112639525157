.nav-body{
    width: 100%;
    min-height: 50px;
    margin: 0;
    top: 0;
    position: sticky;
    position: -webkit-sticky;
    background-color: var(--bg-main);
    z-index: 100;
}

.nav {
    margin: 0;
    padding: 0 10px;
    overflow: hidden;
}

.nav-item {
    display: block;
    float: left;
    list-style: none !important;
    padding: 14px 16px;
    margin: 0 5px;
    color: var(--white);
    text-align: center;
    text-decoration: none;
    border: none;
    border-bottom: 1px solid transparent;
    background-color: var(--bg-main)
}

.nav-item:hover:not(.active){
    border-bottom: 1px solid var(--bg-secondary);
}


.nav-item.active {
    border-bottom: 1px solid var(--bg-comp);
}

.right {
    float: right;
}



.ham-btn {
    background-color: var(--bg-main);
    color: var(--white);
    border: none !important;
    text-decoration: none;
    width: 100%;
    font-size: 35px;
    text-align: left;
}


.ham-btn:hover:not(.active){
    color: var(--bg-secondary);
}

#hamburger {
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

ul.nav li a {
    text-decoration: none !important;
    color: white;
}


@media screen and (max-width: 620px) {
    .nav-item{
        width: 100%;
        margin: 0;
    }
    .nav {
        padding: 0;
    }
    .active{
        background-color: var(--bg-comp);
        border: none;
        border-bottom: 1px solid transparent;
        color: black;
    }
    .nav-item:hover:not(.active){
        background-color: var(--bg-secondary);
        border: none;
        border-bottom: 1px solid transparent;
    }
}
