body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg-main:#5468b7;
  --bg-secondary:#8294db;
  --green: #87c761;
  --bg-comp:#d1a48e;
  --bg-dark: #525e8d;
  --black:rgb(46, 46, 46);
  --white:#f1f1f1;
  --logo-sec:#d9b9aa;
  --logo-prime:#a1826f;
}


.App {
  text-align: center;
  
}


* {
  box-sizing:border-box;
  -moz-box-sizing:border-box;
}

li {
  list-style: none;
}


input {
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  border: none;
  padding: 10px;
  margin: 3px;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  padding: 0;
}
 
h1, h2, h3, h4, h5, h6 {
  letter-spacing: 5px;
  font-weight: 900;
  font-family: 'Playfair display';
  text-transform: uppercase;
  margin: 0;
}

h1 {
  font-size: calc(2em + 3vw);
}

h2 {
  font-size: calc(2em + 2vw);
}

h3 {
  font-size: calc(2em + 1vw);
}

h4 {
  font-size: calc(2em + .5vw);
}

h5 {
  font-size: calc(1.2em);
}

h6 {
  font-size: calc(1em);
}

img {
  color: transparent;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}


.greeting {
  color: #5468b7;
  color: var(--bg-main);
}

.add {
  color: white;
  background-color: #5468b7;
  background-color: var(--bg-main);
}

.remove {
  color: white;
  background-color: #d46464;
}

.update {
  color: black;
  background-color: #efdb5d;
}
.footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
    min-height: 200px;
    background-color: var(--bg-main);
    position: relative;
}

.footer hr {
    border: none;
    height: .5px;
    color: var(--bg-secondary); /* old IE */
    background-color: var(--bg-secondary); /* Modern Browsers */
}

.footer-div {
    width: 33%;
    text-align: left;
    color: var(--white);
    padding-right: 50px;
}

.footer-div a, .footer-div i {
    text-decoration: none !important;
    color: var(--white);
}


.footer-div i {
    font-size: 25px;
}

.footer-div a:hover, .footer-div i:hover {
    color: var(--bg-comp);
}

@media screen and (max-width: 600px) {
    .footer-div {
        width: 100%;
        padding-right: 10%;
    }
}
.landing-container {
    background-color: var(--bg-main);
    color: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0 7% 0;
    min-height: 80vh;

}

.landing-win {
    width: 70%;
    height: 80%;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;

}

.landing-win-inner {
    width: 50%;
    padding: 5%;

}


.l-w-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    background-color: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}



.l-w-left h2{
    text-transform: none;
    letter-spacing: normal;
}

.l-w-right {
    background-color: transparent;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:  var(--white);
}

.l-w-right img {
    width:70%;
    margin: 0px;
}

.link {
    width: 80%;
}

.d-btn {
    height: 40px;
    width: 100%;
    margin: 7px;
    border: none;
    border-radius: 30px;
    background-color: var(--bg-secondary);
    color: white;
    text-align: center;
}

.d-btn:hover {
    opacity: .7;
}

.d-btn:active {
    opacity: 1;
}



@media screen and (max-width:800px) {
    .landing-win-inner {
        width: 100%;
    }
    .landing-win {
        width: 90%;
    }
    .l-w-left {
        border-radius: 0px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .l-w-right {
        border-radius: 0px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}

.inquiry-container{
    height: 90vh;
    background-color: var(--bg-main);
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.blog-container {
    width: 100%;
    background-color: var(--bg-main);
    min-height: 90vh;
    padding-top: 1%;
}


/******* SET *********/

.blog-set {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 5%;

}

/******* POST ********/

.blog-post{
    background-color: var(--white);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    width: 60%;
    border-radius: 10px;
    padding: 15px;
    text-align: left;
    overflow: hidden;
    margin: 20px;
}

.blog-post h4 {
    text-transform: none;
    letter-spacing: normal;
}


#post-prev {
    margin-top: 10px;   
    overflow: hidden;
}

#post-prev::after{
    content: "...";
}

#post-body{
    overflow-y: scroll;
    height: 0;
    transition: .3s ease-out;
    margin-right: -50px; /* Maximum width of scrollbar */
    padding-right: 50px; /* Maximum width of scrollbar */
}

.display {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.btn {
    margin: 0 20px;
    padding: 5px 10%;
    background-color: transparent;
    border-radius: 15px;  
    transition: .3s;
    border: none;
    background-color: var(--bg-main);
    color: white;
}

.btn:active {
    background-color: var(--bg-dark);
}


.blog-post-body {
    justify-items: center;
    align-items: center;
    color: var(--black);
    max-width: 100%;
}

.post-img {
    width: 300px;
}


@media screen and (max-width: 900px) {
    .blog-post {
        width: 80%;
    }
    .display{
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
}

.resume-container {
    background-color: var(--bg-main);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.project-container {
    width: 90%;
    margin: 70px;
}

.resume-div {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.resume-div img {
    max-width: 500px;
}

.projects-div {
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.project-post-body {
    height: 250px;
    width: 350px;
    margin: 1%;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-size: 80%;
    background-blend-mode:color-burn;
    background-position:center;
}

.project-post-body a{
    text-decoration: none;
    color: #262626;
    font-size: 25px;
}

.project-post-body a:hover{
    color: #262626ac;
}

.post-section {
    background-color: rgba(255, 255, 255, 0.7);
    margin: 5px;
    border-radius: 10px;
    padding: 5px;
}

.font-small {
    text-align: left;
    font-size: 13px;
}

.btn-res {
    width: 40%;
    height: 80px;

    background-color: #dd4600;
    color: var(--white);

    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: none !important;
    font-size: 2em;
    box-shadow: 0 9px #cd1a1a;
}

.btn-res:hover {
    opacity: .9;
}


.btn-res:active {
    box-shadow: 0 5px #b41b1b;
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
}


@media screen and (max-width: 600px) {
    .btn-res {
        width: 80%;
    }
}
.nav-body{
    width: 100%;
    min-height: 50px;
    margin: 0;
    top: 0;
    position: sticky;
    position: -webkit-sticky;
    background-color: var(--bg-main);
    z-index: 100;
}

.nav {
    margin: 0;
    padding: 0 10px;
    overflow: hidden;
}

.nav-item {
    display: block;
    float: left;
    list-style: none !important;
    padding: 14px 16px;
    margin: 0 5px;
    color: var(--white);
    text-align: center;
    text-decoration: none;
    border: none;
    border-bottom: 1px solid transparent;
    background-color: var(--bg-main)
}

.nav-item:hover:not(.active){
    border-bottom: 1px solid var(--bg-secondary);
}


.nav-item.active {
    border-bottom: 1px solid var(--bg-comp);
}

.right {
    float: right;
}



.ham-btn {
    background-color: var(--bg-main);
    color: var(--white);
    border: none !important;
    text-decoration: none;
    width: 100%;
    font-size: 35px;
    text-align: left;
}


.ham-btn:hover:not(.active){
    color: var(--bg-secondary);
}

#hamburger {
    transition: all 0.2s ease-in-out;
}

ul.nav li a {
    text-decoration: none !important;
    color: white;
}


@media screen and (max-width: 620px) {
    .nav-item{
        width: 100%;
        margin: 0;
    }
    .nav {
        padding: 0;
    }
    .active{
        background-color: var(--bg-comp);
        border: none;
        border-bottom: 1px solid transparent;
        color: black;
    }
    .nav-item:hover:not(.active){
        background-color: var(--bg-secondary);
        border: none;
        border-bottom: 1px solid transparent;
    }
}

