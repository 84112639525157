.footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
    min-height: 200px;
    background-color: var(--bg-main);
    position: relative;
}

.footer hr {
    border: none;
    height: .5px;
    color: var(--bg-secondary); /* old IE */
    background-color: var(--bg-secondary); /* Modern Browsers */
}

.footer-div {
    width: 33%;
    text-align: left;
    color: var(--white);
    padding-right: 50px;
}

.footer-div a, .footer-div i {
    text-decoration: none !important;
    color: var(--white);
}


.footer-div i {
    font-size: 25px;
}

.footer-div a:hover, .footer-div i:hover {
    color: var(--bg-comp);
}

@media screen and (max-width: 600px) {
    .footer-div {
        width: 100%;
        padding-right: 10%;
    }
}