
.blog-container {
    width: 100%;
    background-color: var(--bg-main);
    min-height: 90vh;
    padding-top: 1%;
}


/******* SET *********/

.blog-set {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 5%;

}

/******* POST ********/

.blog-post{
    background-color: var(--white);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    width: 60%;
    border-radius: 10px;
    padding: 15px;
    text-align: left;
    overflow: hidden;
    margin: 20px;
}

.blog-post h4 {
    text-transform: none;
    letter-spacing: normal;
}


#post-prev {
    margin-top: 10px;   
    overflow: hidden;
}

#post-prev::after{
    content: "...";
}

#post-body{
    overflow-y: scroll;
    height: 0;
    transition: .3s ease-out;
    margin-right: -50px; /* Maximum width of scrollbar */
    padding-right: 50px; /* Maximum width of scrollbar */
}

.display {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.btn {
    margin: 0 20px;
    padding: 5px 10%;
    background-color: transparent;
    border-radius: 15px;  
    transition: .3s;
    border: none;
    background-color: var(--bg-main);
    color: white;
}

.btn:active {
    background-color: var(--bg-dark);
}


.blog-post-body {
    justify-items: center;
    align-items: center;
    color: var(--black);
    max-width: 100%;
}

.post-img {
    width: 300px;
}


@media screen and (max-width: 900px) {
    .blog-post {
        width: 80%;
    }
    .display{
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
}
